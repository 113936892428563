<template>
  <div class="wrapper_main">
    <div class="wrapper_content">
      <div class="content_title">
        <h2>{{ $t('menu.refLinks') }}</h2>

        <p></p>
      </div>

      <div class="referralLinks_content clearfix">
        <div class="referralLinks_select">
          <el-form>
            <AccountNumber :label="$t('referralLinks.ibAcc')"></AccountNumber>

            <SelectForm
              v-model="lang"
              :label="$t('referralLinks.campLang')"
              @change="languageSelectChange"
              name="language"
            >
              <el-option
                v-for="item in languageList"
                :key="item.language"
                :label="item.language"
                :value="item.lang"
                :data-testid="item.language"
              ></el-option>
            </SelectForm>
          </el-form>
        </div>

        <div class="info_box" v-if="accountID">
          <div v-for="link in linkList" v-bind:key="link.name">
            <div class="labelTitle">
              <span class="required_icon">*</span>

              <label for="">{{ link.name }}</label>
            </div>

            <div class="main">
              <p>{{ link.url }}</p>

              <div class="form_button fr">
                <el-button class="purple_button" @click="onCopy(link.url)" data-testid="copy">
                  {{ $t('referralLinks.cpy') }}
                </el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AccountNumber from '@/components/form/AccountNumber';

export default {
  name: 'ReferralLinks',
  components: { AccountNumber },
  data() {
    return {
      lang: '',
      languagePath: '',
      linkList: {
        liveAccountLink: { name: this.$t('referralLinks.liveAcc'), url: '' },
        demoAccountLink: { name: this.$t('referralLinks.demoAcc'), url: '' },
        homePageLink: { name: this.$t('referralLinks.homePage'), url: '' }
      },
      idPrefix: '/?affid=',
      tradingPath: '/get-trading/forex-trading-account',
      demoPath: '/get-trading/open-demo-account'
    };
  },
  watch: {
    accountID() {
      console.log(this.accountID);
      if (this.accountID) this.setLinkList();
    },
    lang() {
      if (this.accountID) this.setLinkList();
    }
  },
  methods: {
    onCopy(item) {
      this.$copyText(item)
        .then(result => {
          this.$message(this.$t('referralLinks.alreadyCPY'));
          console.log('Copied :' + item);
        })
        .catch(err => {
          this.$message('Failed to copy' + err);
          console.log(err);
        });
    },
    setLinkList() {
      this.linkList.liveAccountLink.url = `https://${this.languagePath}${this.getLanguageLink()}${this.tradingPath}${
        this.idPrefix
      }${this.accountID}`;
      this.linkList.demoAccountLink.url = `https://${this.languagePath}${this.getLanguageLink()}${this.demoPath}${
        this.idPrefix
      }${this.accountID}`;
      this.linkList.homePageLink.url = `https://${this.languagePath}${this.getLanguageLink()}${this.idPrefix}${
        this.accountID
      }`;
    },
    setDefaultLanuage() {
      this.languagePath = this.languageList.English.path;
      this.lang = 'en';
    },
    languageSelectChange(item) {
      this.lang = item;
      this.languagePath = this.languageList.English.path;
    },
    getLanguageLink() {
      return this.lang == null || ['', 'en'].includes(this.lang) ? '' : '/' + this.lang;
    }
  },
  mounted() {
    this.setDefaultLanuage();
    this.setLinkList();
  },
  computed: {
    accountID() {
      return this.$store.state.behavior.AID;
    },
    languageList() {
      return {
        English: { language: this.$t('common.lang.english'), path: this.GLOBAL_DOMAIN_WEBSITE, lang: 'en' }
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/referralLinks.scss';
</style>
